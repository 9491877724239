import React from "react";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { LinkText } from "../LinkText";
const Footer = () => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const selected_lang = i18n.language;

	return (
		<footer>
			<Container className="footer">
				<div className="footer-link" aria-label="footerMenu">
					<ul>
						<li>
							<a href={t("FOOTER.TNC_URL")} target="new">
								{t("FOOTER.TNC_COPY")}
							</a>
						</li>
					</ul>
				</div>
				<div className="copy-text">
					<Trans
						i18nKey="FOOTER.COPY_DECK"
						components={{
							link1: <LinkText to={`https://www.lindt.ca/${selected_lang}/Blissfulnights`} title="Lindt.ca/Blissfulnights" className="white-link" />,
							link2: <LinkText to={"https://account.bellmedia.ca/redeem/?continue=https%3A%2F%2Fwww.crave.ca%2F&fallback=https%3A%2F%2Fwww.crave.ca%2Fsignin&service=crave"} title="crave.ca/redeem" className="white-link" />,
						}}
					/>
				</div>
			</Container>
		</footer>
	);
};
export default connect(null, { getManu })(Footer);
