import React from "react";
import Support from "../../components/Support";
import { useTranslation } from "react-i18next";
const SupportScreen = (props) => {
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
		try {
			var postObject = JSON.stringify({
				event: "iframe_page_view",
				page_path: "/support",
				page_title: "Support page - " + i18n.language.toUpperCase()
			});
			window.parent.postMessage(postObject, "https://mcstaging.lindt.ca");
			console.log("postMessage call for Support PageViews ->", postObject);
		} catch (e) {
			console.log("postMessage call on error ->", e);
		}
	}, [i18n.language]);
	return (
		<>
			<Support />
		</>
	);
};

export default SupportScreen;
