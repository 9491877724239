import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import TopRight from "./TopRight";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { saveSelectedMenu } from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Menu = ({ data }) => {
	const { t, i18n } = useTranslation();
	let { lng } = useParams();
	const selected_lang = i18n.language;
	const user = useSelector(({ user }) => user);
	const dispatch = useDispatch();
	const config = useSelector((state) => state.app.config);
	const scroll = (el) => {
		dispatch(saveSelectedMenu(el.id));
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -80;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
		document.getElementById("navbarSupportedContent").classList.remove("show");      
	};
	
	React.useEffect(() => {
		if (["fr", "en"].indexOf(lng.toLowerCase()) >= 0) {
			let curSelectedLang = lng.toLowerCase() === "fr" ? "fr" : "en";
			i18n.changeLanguage(curSelectedLang);
		} else {
			window.location.href = `/en/${lng}`;
		}
	}, [lng]);
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<Fragment>
			<button id="button-navbar-toggler" className="navbar-toggler mob-menu-pos" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span id="span-navbar-toggler-icon" className="navbar-toggler-icon"></span>
			</button>

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav">
					{/*[START:HOME]*/}
					<li className="nav-item">
						<a className="nav-link nav-link-custom" id="href-home" href={`/${selected_lang}`}>
							{t("MENU.HOME")}
						</a>
					</li>
					{/*[END:HOME]*/}
					{/*[START:HTP]*/}
					{config.campaignState !== "partialExpired" ? (
						<>
							<li className="nav-item">
								{/* "/#participate-content" */}
								<HashLink className="nav-link nav-link-custom" id="href-participate" to={`/${selected_lang}/htp-instruction`}>
									{t("MENU.HTP")}
								</HashLink>
							</li>

							{/*[END:HTP]*/}

							{/*[START:REGISTER]*/}
							<li className="nav-item">
								{/* <a id="href-register" className="nav-link" href={`/${selected_lang}/register`}>
									{t("MENU.ENTER_NOW")}
								</a> */}
								<a className="nav-link nav-link-custom" id="href-enternow" href={`/${selected_lang}/#enternow`} scroll={scroll}>
									{t("MENU.ENTER_NOW")}
								</a>
							</li>
						</>
					) : (
						""
					)}
					{/*[END:REGISTER]*/}
					{/*[START:FAQ]*/}
					<li className="nav-item">
						<a id="href-faq" className="nav-link" href={`/${selected_lang}/faq`}>
							{t("MENU.FAQ")}
						</a>
					</li>
					{/*[END:FAQ]*/}
					{/*[START:SUPPORT]*/}
					<li className="nav-item">
						<a id="href-support" className="nav-link" href={`/${selected_lang}/support`}>
							{t("MENU.SUPPORT")}
						</a>
					</li>
					{/*[END:SUPPORT]*/}
					{/*[START:LANGUAGE]*/}
					{/* <li className="nav-item">
						<button id="href-lang" className="nav-link btn btn-link" onClick={languageChangeHandler}>
							{t("MENU.LANG")}
						</button>
					</li> */}
					{/*[END:LANGUAGE]*/}
				</ul>
			</div>
			{/* <TopRight /> */}
		</Fragment>
	);
};

export default Menu;
