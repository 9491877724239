import React from "react";
import ParticipateInstructions from "../../components/ParticipateInstructions";
import { useTranslation } from "react-i18next";
const ParticipateInstructionsScreen = (props) => {
    const { t, i18n } = useTranslation();
    React.useEffect(() => {
        document.title = t("COMMON.TITLE");
        try {
            var postObject = JSON.stringify({
                event: "iframe_page_view",
                page_path: "/htp-instruction",
                page_title: "How to participate page - " + i18n.language.toUpperCase()
            });
            window.parent.postMessage(postObject, "https://mcstaging.lindt.ca");
            console.log("postMessage call for HTP PageViews ->", postObject);
        } catch (e) {
            console.log("postMessage call on error ->", e);
        }
    }, [i18n.language]);
    return (
        <>
            <ParticipateInstructions />
        </>
    );
};

export default ParticipateInstructionsScreen;
