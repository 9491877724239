import React from "react";
import UploadReceiptImage from "../../components/ValidatePurchase/UploadReceiptImage";
import { useTranslation } from "react-i18next";
const UploadReceipt = (props) => {
    const { t, i18n } = useTranslation();
    React.useEffect(() => {
        document.title = t("COMMON.TITLE");
        try {
            var postObject = JSON.stringify({
                event: "iframe_page_view",
                page_path: "/upload-receipt",
                page_title: "Upload receipt page - " + i18n.language.toUpperCase(),
            });
            window.parent.postMessage(postObject, "https://mcstaging.lindt.ca");
            console.log("postMessage call for UploadReceipt PageViews ->", postObject);
        } catch (e) {
            console.log("postMessage call on error ->", e);
        }
    }, [i18n.language]);
    return (
        <>
            <UploadReceiptImage />
        </>
    );
}

export default UploadReceipt;
