import React, { useState } from "react";
import { Container, Row, Col, Accordion, Card, Button, Nav } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
const FrequentlyAskedQuestions = () => {

	const [data, setData] = useState([
		{
			id: 0, clicked: false, name: "FAQ.Q1",
			content: "FAQ.A1",
		},
		{
			id: 1, clicked: false, name: "FAQ.Q2",
			content: "FAQ.A2",
		},
		{
			id: 2, clicked: false, name: "FAQ.Q3",
			content: "FAQ.A3",
		},
		{
			id: 3, clicked: false, name: "FAQ.Q4",
			content: "FAQ.A4",
		},
		{
			id: 4, clicked: false, name: "FAQ.Q5",
			content: "FAQ.A5",
		},
		{
			id: 5, clicked: false, name: "FAQ.Q6",
			content: "FAQ.A6",
		},
		{
			id: 6, clicked: false, name: "FAQ.Q7",
			content: "FAQ.A7",
		},
		{
			id: 7, clicked: false, name: "FAQ.Q8",
			content: "FAQ.A8",
		},
		{
			id: 8, clicked: false, name: "FAQ.Q9",
			content: "FAQ.A9",
		},
		{
			id: 9, clicked: false, name: "FAQ.Q10",
			content: "FAQ.A10",
		},
		{
			id: 10, clicked: false, name: "FAQ.Q11",
			content: "FAQ.A11",
		},
	]);
	const { t, i18n } = useTranslation();
	const [faqStatus, setFaqStatus] = useState(false);
	const toggleHandler = (i) => {
		let tempData = JSON.parse(JSON.stringify(data));
		tempData[i].clicked = tempData[i].clicked ? false : true;
		setData(tempData);
	};
	const bulkActionHandler = () => {
		let status = !faqStatus;
		setFaqStatus(status);
		let tempData = data.map((v) => ({ ...v, clicked: status }));
		setData(tempData);
	};
	return (
		<Container className="justify-content-md-center section-content" id="faq-content">
			<section role="contentinfo" aria-label="FAQ">
				<Row className="justify-content-md-center section-content">
					<Col md={12}>
						{/* <h1 className="section-heading text-center mb-5">FREQUENTLY ASKED QUESTIONS</h1> */}
						<h1 className="main-heading section-heading text-center">{t("FAQ.HEADING")}</h1>
					</Col>
					<Col md={12} className="text-right">
						<a onClick={bulkActionHandler} variant="link" className="remove-underline">
							{!faqStatus ? <span>{t("FAQ.OPEN_ALL")}</span> : <span>{t("FAQ.CLOSE_ALL")}</span>}
						</a>
					</Col>
					<Col md={12} className="faq-page p-0">
						<Accordion>
							{data.map((v, i) => {
								return (
									<Card style={{ marginBottom: "16px" }}>
										<Card.Header>
											<Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" className="p-0" onClick={(e) => toggleHandler(i)} eventKey={v.id}>
												<Row style={{ cursor: "pointer" }}>
													<Col className="col-11">{t(v.name)}</Col>
													<Col className="col-1 text-right pl-0 faq-line-icon">{v.clicked ? "-" : "+"}</Col>
												</Row>
											</Accordion.Toggle>
										</Card.Header>
										{v.clicked ? (
											<Accordion>
												<Card.Body dangerouslySetInnerHTML={{ __html: t(v.content) }} />
											</Accordion>
										) : (
											<Accordion.Collapse>
												<Card.Body dangerouslySetInnerHTML={{ __html: t(v.content) }} />
											</Accordion.Collapse>
										)}
									</Card>
								);
							})}
						</Accordion>
					</Col>
				</Row>
			</section>
		</Container >
	);
};

export default FrequentlyAskedQuestions;
