import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ThankYou = (props) => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
    });
    const { t, i18n } = useTranslation();
    React.useEffect(() => {
        document.title = t("COMMON.TITLE");
        try {
            var postObject = JSON.stringify({
                event: "iframe_page_view",
                page_path: "/thankyou",
                page_title: "Thank you page - " + i18n.language.toUpperCase()
            });
            window.parent.postMessage(postObject, "https://mcstaging.lindt.ca");
            console.log("postMessage call for Thankyou PageViews ->", postObject);
        } catch (e) {
            console.log("postMessage call on error ->", e);
        }
    }, [i18n.language]);
    return (
        <>
            <div className="thankspage py-5">
                <div className="container">
                    <Row className="justify-content-center">
                        <Col className="col-sm-8">
                            <h1 className="white mt-5">{t("Thank_you")}</h1>
                            <h2 className="white">{t("Your receipt has been received")}</h2>
                            <div>
                                <p className="mt-5 text-center">{t("thank_p_one")}</p>
                                <p className="mt-4 text-center">{t("thank_p_two_a")} <a href="mailto:support@receiptprocessor.com">{t("support_receiptprocessor_com")}</a> {t("thank_p_two_b")}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default ThankYou;
