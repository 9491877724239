import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppRoute from "./AppRoute";
import PublicLayout from "../components/layout/PublicLayout";
// import Register from "../screen/Signup/Register";
import Home from "../screen/Home";
import PrivateRoute from "./PrivateRoute";
import UploadReceipt from "../screen/ValidatePurchase/UploadReceipt";
import Upcoming from "../screen/CampaignStatus/Upcoming";
import Expired from "../screen/CampaignStatus/Expired";
import SupportScreen from "../screen/SupportScreen";
import FaqScreen from "../screen/FaqScreen";
import ThankYou from "../screen/ValidatePurchase/ThankYou";
import SignupScreen from "../screen/SignupScreen";
import ParticipateInstructionsScreen from "../screen/ParticipateInstructionsScreen/index";
const routes = (
	// <>{/:lng(en|lt)?}
	<Switch>
		{/* <AppRoute exact path={{/:lng(en|fr)? }} component={Home} layout={PublicLayout} /> */}
		<AppRoute exact path="/:lng" component={Home} layout={PublicLayout} />
		{/* <AppRoute exact path="/:lng/register" component={Register} layout={PublicLayout} /> */}
		<AppRoute exact path="/:lng/register" component={SignupScreen} layout={PublicLayout} />
		<PrivateRoute exact path="/:lng/upload-receipt" component={UploadReceipt} layout={PublicLayout} />
		<Route exact path="/:lng/upcoming" component={Upcoming} />
		<Route exact path="/:lng/expired" component={Expired} />
		<AppRoute exact path="/:lng/support" component={SupportScreen} layout={PublicLayout} />
		<AppRoute exact path="/fr/support" component={SupportScreen} layout={PublicLayout} />
		<AppRoute exact path="/:lng/htp-instruction" component={ParticipateInstructionsScreen} layout={PublicLayout} />
		<AppRoute exact path="/:lng/faq" component={FaqScreen} layout={PublicLayout} />
		<AppRoute exact path="/:lng/thankyou" component={ThankYou} layout={PublicLayout} />
		<Redirect from="/" to="/en" />
	</Switch>
	// </>
);
export default routes;
