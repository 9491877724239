import React from "react";
import CarouselCustom from "../../components/CarouselCustom";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import SignupScreen from "../SignupScreen/index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const Home = (props) => {
	const { t, i18n } = useTranslation();
	const config = useSelector((state) => state.app.config);
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
		try {
			var postObject = JSON.stringify({
				event: "iframe_page_view",
				page_path: "/home",
				page_title: "Home page - " + i18n.language.toUpperCase(),
			});
			window.parent.postMessage(postObject, "https://mcstaging.lindt.ca");
			console.log("postMessage call for Home PageViews ->", postObject);
		} catch (e) {
			console.log("postMessage call on error ->", e);
		}
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			{config.campaignState !== "partialExpired" && <SignupScreen />}
		</>
	);
};

export default IsLoadingHOC(Home, "");
