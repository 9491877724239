import React from "react";
import { useTranslation } from "react-i18next";
import { Carousel, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { carouselData_en, carouselData_fr, carouselData_en_exp, carouselData_fr_exp } from "../data";
const CarouselCustom = () => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const dispatch = useDispatch();
	const config = useSelector((state) => state.app.config);
	const selected_lang = i18n.language;
	const carouselData = [];

	// if (selected_lang == "en") {
	// 	carouselData = [
	// 		{
	// 			img: "public/images/desktop-banner_en.png",
	// 			mobile_img: "public/images/mobile-banner_en.png",
	// 			title: "Lindt Crave Code GWP",
	// 		},
	// 	];
	// } else if (selected_lang == "fr") {
	// 	carouselData = [
	// 		{
	// 			img: "public/images/desktop-banner_fr.png",
	// 			mobile_img: "public/images/mobile-banner_fr.png",
	// 			title: "indt Crave Code GWP",
	// 		},
	// 	];
	// }

	return (
		<Container className="carouselCustom" fluid>
			<div className="row justify-content-center" id="home">
				{
					<Carousel>
						<div>
							{selected_lang == "en" ? (
								<div>
									{config.campaignState !== "partialExpired"
										? carouselData_en.map((item, index) => {
												return (
													<>
														{!user.accessToken && config.campaignState !== "partialExpired" ? (
															<a href="/register" className="w-100">
																<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</a>
														) : (
															<>
																<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</>
														)}
													</>
												);
										  })
										: carouselData_en_exp.map((item, index) => {
												return (
													<>
														{!user.accessToken && config.campaignState !== "partialExpired" ? (
															<a href="/register" className="w-100">
																<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</a>
														) : (
															<>
																<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</>
														)}
													</>
												);
										  })}
								</div>
							) : (
								<div>
									{config.campaignState !== "partialExpired"
										? carouselData_fr.map((item, index) => {
												return (
													<>
														{!user.accessToken && config.campaignState !== "partialExpired" ? (
															<a href="/register" className="w-100">
																<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</a>
														) : (
															<>
																<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</>
														)}
													</>
												);
										  })
										: carouselData_fr_exp.map((item, index) => {
												return (
													<>
														{!user.accessToken && config.campaignState !== "partialExpired" ? (
															<a href="/register" className="w-100">
																<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</a>
														) : (
															<>
																<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</>
														)}
													</>
												);
										  })}
								</div>
							)}
						</div>
					</Carousel>
				}
			</div>
		</Container>
	);
};

export default CarouselCustom;
