import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert, Section } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import IsLoadingHOC from "./IsLoadingHOC";
import { helpQueryAction } from "../redux/actions";
import { toast } from "react-toastify";
import Captcha from "./Captcha";
import { useSelector } from "react-redux";
import Required from "../components/Required";
const Support = (props) => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const selected_lang = i18n.language;
	const [data, setData] = useState({
		name: "",
		email: "",
		message: "",
		captchaVal: "",
	});
	const config = useSelector((state) => state.app.config);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	const [error, setError] = useState({ name: "", email: "", message: "", captchaVal: "" });
	// eslint-disable-next-line
	const validationError = { message: "", name: /^[A-Za-z]+$/, email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, captchaVal: "" };
	let [captchaEnable, setCaptchaEnable] = useState(false);
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captchaVal"] = "";
			let reqd = JSON.parse(JSON.stringify(data));
			reqd.captchaVal = captchaValue;
			setData(reqd);
		}
		setError(errorArray);
		// eslint-disable-next-line
	}, [captchaValue]);
	useEffect(() => {
		if (config.captchaConfig) {
			if (config.captchaConfig.supportCaptcha) {
				setCaptchaEnable(true);
			}
		}
	}, [config]);
	const handleChange = (e) => {
		let key = e.target.name;
		let val = e.target.value;
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));
		if (val.match(validationError[key]) || val === "" || key === "email") {
			reqd[key] = val;
		}
		if (key === "name") {
			if (!val || val.trim().length < 1) {
				errd[key] = "SUPPORT.ERROR.NAME";
			} else {
				errd[key] = ``;
			}
		} else if (key === "email") {
			if (!val.match(validationError[key])) {
				errd[key] = "SUPPORT.ERROR.EMAIL";
			} else {
				errd[key] = ``;
			}
		} else if (key === "message") {
			if (!val || val.trim().length < 1) {
				errd[key] = "SUPPORT.ERROR.MESSAGE";
			} else {
				errd[key] = ``;
			}
		}
		setError(errd);
		setData(reqd);
	};
	const onSubmit = () => {
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));
		let isError = false;
		for (let key in reqd) {
			let val = reqd[key].trim();
			if (key === "name") {
				if (!val || val.length < 1) {
					isError = true;
					errd[key] = "SUPPORT.ERROR.NAME";
				} else {
					errd[key] = ``;
				}
			} else if (key === "email") {
				if (!val.match(validationError[key])) {
					isError = true;
					errd[key] = "SUPPORT.ERROR.EMAIL";
				} else {
					errd[key] = ``;
				}
			} else if (key === "message") {
				if (!val || val.length < 1) {
					isError = true;
					errd[key] = "SUPPORT.ERROR.MESSAGE";
				} else {
					errd[key] = ``;
				}
			} else if (key === "captchaVal" && captchaEnable) {
				if (!val || val.length < 2) {
					isError = true;
					errd[key] = "SUPPORT.ERROR.CAPTCHA_VAL";
				} else {
					errd[key] = ``;
				}
			}
		}
		setError(errd);
		if (!isError) {
			props.setLoading(true);
			let requestData = { email: reqd.email, name: `${reqd.name} `, message: reqd.message };
			if (captchaEnable) {
				requestData["captchaVal"] = reqd.captchaVal;
			}
			helpQueryAction(requestData)
				.then((response) => {
					for (let key in reqd) {
						reqd[key] = "";
					}
					setResetCaptchaValue(resetCaptchaValue + 1);
					setData(reqd);
					props.setLoading(false);
					toast.success(t("SUPPORT.SUBMIT.SUCCESS"));

					try {
						var postObject = JSON.stringify({
							event: "iframe_form_submit",
							form: "support"
						});
						window.parent.postMessage(postObject, 'https://mcstaging.lindt.ca');
						console.log("postMessage call on success ->", postObject)

					} catch (e) {
						console.log("postMessage call on error ->", e)
					}
				})
				.catch((error) => {
					toast.error(t("COMMON.SOMTHING_WRONG"));
					props.setLoading(false);

					try {
						var postObject = JSON.stringify({
							event: "iframe_form_submit",
							form: "support_failure"
						});
						window.parent.postMessage(postObject, 'https://mcstaging.lindt.ca');
						console.log("postMessage call on failure ->", postObject)
					} catch (e) {
						console.log("postMessage call on error ->", e)
					}
				});
		}
	};
	return (
		<Container className="justify-content-md-center" id="support-content">
			<section role="contentinfo" aria-label="Support" className="register-page">
				<Form>
					<h1 className="main-heading text-center">{t("SUPPORT.HEADING")}</h1>
					{selected_lang == "en" ? (
						<div>
							<p className="text-left small">
								The customer support representative will be available via email to offer support to users experiencing issues with navigating the site, uploading images to the microsite, and receiving appropriate emails. This will be done through the email <a href="mailto:support@receiptprocessor.com">support@receiptprocessor.com</a> unless otherwise stated. Support will be
								provided in English. Our customer support team will do our best to get back to you within 3 business days.
							</p>
						</div>
					) : (
						<div>
							<p className="text-left small">
								Le représentant du service à la clientèle sera joignable par courriel pour offrir une assistance aux utilisateurs qui rencontrent des problèmes en lien avec la navigation sur le site, le téléversement des images sur le microsite ou la réception des courriels. Cela se fait par le biais de l’adresse{" "}
								<a href="mailto:support@receiptprocessor.com">support@receiptprocessor.com</a>, sauf indication contraire. L’assistance sera fournie en anglais. L’équipe de notre service à la clientèle fera de son mieux pour répondre à votre demande dans un délai de 3 jours ouvrables.
							</p>
						</div>
					)}

					{/* <p className="text-center small">{t("SUPPORT.SPECIAL_INSTRUCTIONS")}
					</p> */}
					<Row className="justify-content-md-center">
						<Col md={9}>
							<Row className="mt-5">
								{/*[START:name]*/}
								<div className="form-group col-sm-6 theme-signup-name">
									<label htmlFor="name" className="col-form-label">
										{t("SUPPORT.NAME")}
										<Required />
									</label>
									<div className="">
										<input type="text" data-test="register-name" value={data.name} name="name" onChange={handleChange} className="form-control" id="name" />
										<span className="input-error">{t(error.name)}</span>
									</div>
								</div>
								{/*[END:name]*/}

								{/*[START:Email]*/}
								<Col className="form-group theme-profile-email">
									<label htmlFor="email" className="col-form-label">
										{t("SUPPORT.EMAIL")}
										<Required />
									</label>
									<div className="">
										<input type="email" data-test="profile-email" name="email" className="form-control" value={data.email} onChange={handleChange} id="email" />
										<span className="input-error">{t(error.email)}</span>
									</div>
								</Col>
								{/*[END:Email]*/}
							</Row>

							<Row md={1}>
								<Col sm={12} className="form-group theme-signup-email ">
									<label className="col-form-label" htmlFor="supportmsg">
										{t("SUPPORT.MESSAGE")}<Required />{" "}
									</label>
									<Form.Control size="lg" as="textarea" id="supportmsg" value={data.message} onChange={handleChange} name="message" style={{ height: "80px" }} onFocus={handleChange} />
									<span className="input-error">{t(error.message)}</span>
								</Col>
							</Row>
							{captchaEnable ? (
								<Row md={1}>
									<Col>
										<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
											Google captcha
										</label>
										<Captcha align="left" reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
										{/* <div className="input-error text-left">{error.captchaVal}</div> */}
										<span className="input-error">{t(error.captchaVal)}</span>
									</Col>
								</Row>
							) : (
								""
							)}
							<Row md={1}>
								<Col className="text-left">
									<Button variant="primary" className="btn btn-primary" id="register-submit-btn" data-test="register-submit" onClick={onSubmit}>
										{t("SUPPORT.SUBMIT.LABEL")}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</section>
		</Container>
	);
};

export default IsLoadingHOC(Support, "Wait .....");
