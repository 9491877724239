import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ParticipateInstructions = () => {
	const config = useSelector((state) => state.app.config);
	const { t, i18n } = useTranslation();
	const selected_lang = i18n.language;
	if (config.campaignState === "partialExpired") {
		return (
			<Row className="justify-content-md-center section-content" id="participate-content">
				<Col md={12} className="section-content">
					<h1 className="text-center">{t("COMMON.PARTIAL_EXPIRED")}</h1>
				</Col>
			</Row>
		)
	} else {
		return (
			<section role="contentinfo" id="participate-content">
				<Row className="justify-content-md-center section-content" aria-label="content">
					<Col md={12}>
						{/* <h1 className="section-heading text-center mb-5">FREQUENTLY ASKED QUESTIONS</h1> */}
						<h1 className="main-heading section-heading text-center">{t("HTP.HEADING")}</h1>
					</Col>
					<Col md={9} className="text-center">

						<h1 className="htp-heading mt-5">
							{t("HTP.STEP_1.TITLE")}
						</h1>
						<p className="htp-content">
							{t("HTP.STEP_1.DESCRIPTION")}
						</p>
						<h1 className="htp-heading mt-5">
							{t("HTP.STEP_2.TITLE")}
						</h1>
						<p className="htp-content">
							{t("HTP.STEP_2.DESCRIPTION")}
						</p>
						<h1 className="htp-heading mt-5">
							{t("HTP.STEP_3.TITLE")}
						</h1>
						<p className="htp-content">
							{t("HTP.STEP_3.DESCRIPTION")}
						</p>
						<h1 className="htp-heading mt-5">
							{t("HTP.STEP_4.TITLE")}
						</h1>
						<p className="htp-content">
							{t("HTP.STEP_4.DESCRIPTION")}
						</p>
						<div className="text-center">
							<a href={`/${selected_lang}`} className="btn btn-primary">{t("BUTTON.ENTER_NOW")}</a>
						</div>
					</Col>
				</Row>
				{/* </div> */}
			</section>
		);
	}
}

export default ParticipateInstructions;
